<template>
  <div class="my-programs-nav">
    <ul class="nav nav-tabs">
      <router-link
        v-for="(tab, index) in tabs"
        :key="index"
        tag="li"
        :to="{name: tab.href}"
        class="nav-item"
      >
        <a class="nav-link"><span>{{ tab.label }}</span></a>
      </router-link>
      <li class="search">
        <search-input
          v-model="search"
          @input="$emit('searching', search)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput'
export default {
  name: 'ClinicianListNav',
  components: {
    SearchInput
  },
  props: ['tabs', 'showSearch'],
  data () {
    return {
      search: ''
    }
  }
}
</script>
