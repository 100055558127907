<template>
  <div class="clinicians-list">
    <ClinicianListNav
      :tabs="tabs"
      :show-search="true"
      @searching="searching"
    />
    <vue-good-table
      :columns="columns"
      :rows="clinicians"
      style-class="vgt-table clinician-teams-table"
      :sort-options="{ enabled: false }"
      :search-options="{
        enabled: true,
        externalQuery: search
      }"
      :fixed-header="true"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.label == $t('clinicianListTable.clinician')"
          class="td-name"
        >
          <initials-circle
            first-id="responsive-outer-circle"
            second-id="responsive-inner-circle"
            class="gray-border-circle initials-circle"
            :first="props.row.first_name"
            :second="props.row.last_name"
          />
          <span class="patient-info">
            <span class="name clinician-name"><strong>{{ props.row.full_name }}</strong></span>
            <span class="id">Id: {{ props.row.id }}</span>
          </span>
        </span>
        <span v-else-if="props.column.label == $t('clinicianListTable.teams')">
          {{ props.row.teams.map(team => team.name).join(', ') }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        {{ $t('noResultsFound') }}
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import InitialsCircle from '@/components/common/InitialsCircle'
import ClinicianListNav from '@/components/clinician/list/ClinicianListNav'
import { mapGetters } from 'vuex'
import { CliniciansList } from '@/mixins/TableColumnsHelper'
import { GET_ALL_CLINICIANS } from '@/store/modules/clinicians/constants.js'

export default {
  name: 'CliniciansList',
  components: {
    VueGoodTable,
    InitialsCircle,
    ClinicianListNav
  },
  mixins: [CliniciansList],
  props: ['patients'],
  data () {
    return {
      search: ''
    }
  },
  computed: {
    tabs () {
      const tabs = [
        {
          label: 'Clinicians',
          name: 'my',
          href: 'ClinicianList'
        }
      ]
      if (this.$store.getters.generalSettings.programs_enabled) {
        tabs.push({
          label: 'Clinician Teams',
          name: 'teams',
          href: 'ClinicianTeamsList'
        })
      }
      return tabs
    },
    ...mapGetters({
      clinicians: 'getClinicians'
    })
  },
  created () {
    this.$store.dispatch(GET_ALL_CLINICIANS).catch(e => this.$handleApiError(e))
  },
  methods: {
    searching (search) {
      this.search = search
    }
  }
}
</script>
